//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from './step-layout.vue';
import stepMixin from '../lib/step-mixin.js';
import congratulationsImg from '$resources/images/enhorabuena.png';
import ContractDenyScoring from './Scoring/contract-deny-scoring.vue';

export default {
  components: {
    StepLayout,
    ContractDenyScoring
  },
  mixins: [stepMixin],
  props: {
    scoring: String
  },
  data: () => ({
    congratulationsImg
  }),
  methods: {}
};
