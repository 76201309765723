//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from '../step-layout.vue';
import panaImg from '$resources/images/error-girl.png';

export default {
  name: 'ErrorScoringPage',
  components: {
    StepLayout
  },
  data: () => ({
    panaImg
  })
};
